import React, { FunctionComponent, HTMLProps } from 'react';
import cn from 'classnames';

import './index.scss';

export const SiteFooter: FunctionComponent<React.PropsWithChildren<HTMLProps<HTMLElement>>> = ({ className }) => {
  const currentYear = (new Date()).getUTCFullYear();
  return (
    <footer className={cn(className, "footer")}>
      <div className="footer__ltd">
        <p>GrahamHarper.net Ltd is a company registered in England and Wales, no. 11202127</p>
        <address>Registered office: <span className="footer__address">5th Floor, BLOK, One Castlepark, Tower Hill, Bristol, United Kingdom, BS2 0JA</span></address>
      </div>
      <p className="footer__copyright">© 2018-{currentYear} GrahamHarper.net Ltd</p>
    </footer>
  );
};
